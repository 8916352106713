<template>
  <div id="lstUser" class="lstUser__wrap">
    <CCard>
      <CCardBody>
        <div class="flex1">
          <label>
            {{$t('LIST_USER_MANAGEMENT_TITLE')}}
          </label>
          <CButton :class="{'disabled': !hasPermissionAdd}" @click="createUser" class="btn btn-success btn-md btn-new">
            + {{$t('LIST_USER_MANAGEMENT_BUTTON_ADD_OPERATOR')}}
          </CButton>
        </div>
        <SearchUserComponent
          :onSearch="onSearch"
          :reset="reset"
        />
        <ListUserTableComponent
          @delete-user="openModal"
          :limit="limit"
          :activePage="activePage"
          :users="users"
          :total="total"
          :hasPermissionEdit="hasPermissionEdit"
          :hasPermissionDelete="hasPermissionDelete"
          @pushPage="onChangePage"
        />
        <div class="pagination-wrap pagination--user">
          <StakingPagination
            :limit="limit"
            :total="total"
            :activePage="activePage"
            @changePage="onChangePage"
            @changePageSize="onChangePageSize"
          />
        </div>
      </CCardBody>
    </CCard>
    <CModal id="deleteModal" :show.sync="warningModal" :centered="true">
      <template #header>
        <CButtonClose @click="warningModal = false" />
      </template>
      <div class>
        <div class="pb-4 tit-dele">
          <p>{{$t('LIST_USER_MANAGEMENT_MODAL_TITLE_DELETE_ACCOUNT')}}</p>
        </div>
        <div class="text-modal" v-html="$t('LIST_USER_MANAGEMENT_MODAL_CONTENT_DELETE_ACCOUNT', {name: nameToDelete})">
        </div>
      </div>

      <template #footer>
        <CButton
          class="cancel-btn"
          @click="warningModal = false"
          color="dark"
          variant="outline"
        >{{$t('LIST_USER_MANAGEMENT_MODAL_BUTTON_CANCEL')}}</CButton>
        <CButton @click="deleteUser" class="delete-btn">{{$t('LIST_USER_MANAGEMENT_MODAL_BUTTON_DELETE')}}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import ListUserTableComponent from '@/components/UserAccount/ListUserTableComponent';
import SearchUserComponent from '@/components/UserAccount/SearchUserComponent';
import { cilTrash, cilMedicalCross, cibSolus, cilPlus } from '@coreui/icons';
import { endpoints, env } from '@/constants';
import { mapState, mapActions } from 'vuex';
import responseHelper from '@/utils/response-helper';
import StakingPagination from '@/components/StakingPagination';
import sessionStorageHelper from '@/utils/sessionStorage-helper';

export default {
  name: 'ListUsers',
  components: {
    ListUserTableComponent,
    SearchUserComponent,
    StakingPagination,
  },
  data() {
    return {
      cilTrash,
      cilMedicalCross,
      cilPlus,
      warningModal: false,
      users: [],
      dataSearch: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      idToDelete: null,
      nameToDelete: '',
      pageInput: 1,
      hasPermissionEdit: false,
      hasPermissionAdd: false,
      hasPermissionDelete: false,
    };
  },
  async mounted() {
    this.checkPermission();
    this.onSearch();
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
    ...mapState({
      userPermission: state => state.auth.userPermissions,
    }),
  },

  methods: {
    ...mapActions(['getAction']),
    async deleteUser() {
      try {
        this.warningModal = false;
        const result = await this.$http.delete(endpoints.deleteUser(this.idToDelete));
        if (result.data) {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: this.$t('LIST_USER_MANAGEMENT_NOTIFY_SUCCESS'),
            text: this.$t('LIST_USER_MANAGEMENT_NOTIFY_ERROR_MESSAGE'),
            duration: 1000,
          });
        }
        const data = await this.fetchUsers();
        if (data) {
          this.users = (data.items || []).slice();
        }
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_USER_MANAGEMENT_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
    async createUser() {
      await this.getAction('create');
      this.$router.push('user-management/create-user');
    },
    async fetchUsers(data) {
      const searchKey = data || {};
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          name: searchKey.name || '',
          email: searchKey.email || '',
        };
        const result = await this.$http.get(endpoints.getUsers, { params });
        this.users = result.data.items;
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;
      const searchKey = this.dataSearch;
      const data = await this.fetchUsers(searchKey);
      if (!data) {
        return;
      }
      this.users = data.items.slice();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.fetchUsers();
    },
    openModal(id, name) {
      this.idToDelete = id;
      this.nameToDelete = name;
      this.warningModal = true;
    },
    checkPermission(){
      if (this.userPermission.length > 0){
        this.userPermission.forEach(el => {
          if (el.name === 'CREATE_USER' ){
             this.hasPermissionAdd = true;
          }
          else if (el.name === 'UPDATE_USER'){
             this.hasPermissionEdit = true;
          }
             else if (el.name === 'DELETE_USER'){
             this.hasPermissionDelete = true;
          }
        });
      }
    },
    async onSearch(searchData){
      const data = searchData || sessionStorageHelper.getData('filter-operators');
      this.activePage = 1;
      await this.fetchUsers(data);
    },
    async reset() {
      this.activePage = this.pageInput = 1;
      this.dataSearch = {};
      await this.fetchUsers();
    }
  },
};
</script>

<style lang="scss">
#lstUser /deep/ {
  padding: 0 24px;
  $green: #4dc1a1;
  .flex1 {
    text-align: end;
    margin-bottom: 5px;
  }
  .deleteIcon {
    fill: #ff0000;
  }
  .modal-body {
    padding: 1.3rem 1.5rem;
    .tit-dele{
      p{
        font-size: 18px;
      }
    }
  }
  .modal-content {
    width: 85%;
    .modal-header {
      border: none;
      padding: 0 8px;
      display: none;
    }
    .text-modal {
      strong {
        color: #3a4a7f;
        font-weight: 500;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
      .delete-btn {
        background-color: #ff5555;
        color: #ffffff;
        width: 150px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
      }
      .cancel-btn {
        width: 150px;
        border: 2px solid #cbcbcb;
        color: #657187;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
.lstUser__wrap {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }
  .card-body {
    padding-top: 10px;
    padding-bottom: 10px;
    .flex1 {
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      label {
        color: #657187;
        // font-family: "SF UI Display";
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0;
      }
      .btn-new {
        padding: 0;
        width: 170px;
        height: 32px;
        border-radius: 32px;
        background: #3b4b7e;
        border-color: unset;
        .pr--plus {
          svg {
            margin-top: 0;
          }
        }
      }
    }
    #lstUserTable {
      margin-bottom: 17px;
      thead {
        th {
          padding: 9px;
          color: #4d5666;
          // font-family: "SF UI Display";
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.5px;
          &:nth-last-of-type(2) {
            text-align: center;
          }
        }
      }
      tbody {
        td {
          padding: 8px;
          color: #4d5666;
          // font-family: "SF UI Display";
          font-size: 14px;
        }
        .users-action {
          // padding: .75em 0;
          .remove-icon {
            margin-left: 20px !important;
            @media only screen and (max-width: 1024px) {
              margin-left: 8px !important;
            }
          }
        }
      }
    }
    .pagination--user {
      .pagination {
        margin-bottom: 5px;
        .page-item {
          &:last-child,
          &:first-child {
            width: 34px;
            height: 35px;
          }
          &:not(:last-child):not(:first-child) {
            width: 32px;
            height: 35px;
          }
        }
      }
    }
  }
}
</style>
