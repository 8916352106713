<template>
  <div class="form-search">
    <ul class="row group-fields">
      <li class="col-md-3 col-sm-12">
        <ValidationProvider name="Name" v-slot="{ errors }" rules="">
          <CInput
            :label="$t('SEARCH_USER_COMPONENT_LABEL_NAME')"
            type="text"
            v-model="searchData.name"
            class="form-group"
            @keyup.enter="search()"
          />
          <CRow>
            <CCol :md="12">
              <span class="error-msg mt-1">{{errors[0]}}</span>
            </CCol>
          </CRow>
        </ValidationProvider>
      </li>

      <li class="col-md-3 col-sm-12">
        <ValidationProvider name="Email" v-slot="{ errors }" rules="">
          <CInput
            :label="$t('SEARCH_USER_COMPONENT_LABEL_EMAIL')"
            type="text"
            v-model="searchData.email"
            class="form-group"
            @keyup.enter="search(searchData)"
          />
          <CRow>
            <CCol :md="12">
              <span class="error-msg mt-1">{{errors[0]}}</span>
            </CCol>
          </CRow>
        </ValidationProvider>
      </li>
    </ul>
    <div class="group-button-search">
      <div class="col-md-6 btn-disabled"></div>
      <div class="col-md-3 col-sm-12 custom-btn-white">
        <CButton @click="onReset()">
          {{$t('SEARCH_USER_COMPONENT_BUTTON_RESET')}}
        </CButton>
      </div>
      <div class="col-md-3 col-sm-12 btn-search">
        <CButton @click="search()">
          {{$t('SEARCH_USER_COMPONENT_BUTTON_SEARCH')}}
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import sessionStorageHelper from '@/utils/sessionStorage-helper';

export default {
  name: 'SearchUsers',

  data() {
    return {
      searchData: sessionStorageHelper.getData('filter-operators') || this.getFilterDefault(),
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    reset: {
      type: Function,
      default: null,
    },
    downloadCSV: {
      type: Function,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    kycOpyions: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    getFilterDefault() {
      return {
        name: '',
        email: '',
      };
    },
    onReset() {
      this.searchData = this.getFilterDefault();
      this.search();
    },

    async search() {
      sessionStorageHelper.setData('filter-operators', this.searchData);

      const searchData = {
        ...this.searchData,
      };

      this.onSearch(searchData);
    },
  },
};
</script>

<style lang="scss">
  @mixin member-btn{
    width: 100%;
    color: #1A2958;
    font-size: 14px;
    line-height: 32px;
    background: #fff;
    border: 1px solid #1A2958;
    border-radius: 32px;
    height: 32px;
    padding: 0 25px;
    text-align: center;
    min-width: 150px;
    padding: 0;
    cursor: pointer;
  }
  .form-search{
    .group-fields {
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      padding: 0;
      list-style: none;
      margin-top: 25px;
      margin-bottom: 0;
    }
    .group-button-search{
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      padding: 0;
      margin: 0 -15px 15px;
      .custom-btn-white{
        margin-bottom: 15px;
        button{
          @include member-btn;
        }
      }
      .btn-search{
        button{
          display: inline-block;
          background: #3b4b7e;
          border: none;
          border-radius: 32px;
          width: 100%;
          height: 32px;
          color: #fff;
          margin: 0;
        }
      }
      .btn-disabled{
        @media only screen and( max-width: 767px ){
          display: none;
        }
      }
    }
  }
</style>
