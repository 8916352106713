var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{attrs:{"id":"lstUserTable"}},[_c('CDataTable',{attrs:{"items":_vm.users,"fields":_vm.fields,"itemsPerPage":_vm.limit,"fixed":"","hover":"","striped":"","bordered":"","pagination":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage - 1) * _vm.limit))])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('CBadge',[(item.user_sts === 'ACTIVATED')?_c('img',{attrs:{"src":require("@/assets/images/ico-user-active.svg")}}):_vm._e(),(item.user_sts === 'UNACTIVATED')?_c('img',{attrs:{"src":require("@/assets/images/ico-user-deactive.svg")}}):_vm._e()])],1)]}},{key:"country_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.country ? _vm.$t(("COUNTRY_TYPE_" + (item.country.name.toUpperCase()))) : '-')+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center users-action"},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""},on:{"click":function($event){return _vm.edit(item.id)}}}),_c('img',{staticClass:"ml-3 hand remove-icon",class:{ disabled: item.id === _vm.userId },attrs:{"src":require("@/assets/images/ico-del-user.svg")},on:{"click":function($event){return _vm.remove(item.id, item.name)}}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }