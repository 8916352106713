<template>
  <CCard id="lstUserTable">
    <CDataTable :items="users" :fields="fields" :itemsPerPage="limit" fixed hover striped bordered pagination>
      <template ##="{ item, index }">
        <td>{{ index + 1 + (activePage - 1) * limit }}</td>
      </template>

      <template #status="{ item }">
        <td class="text-center">
          <!-- <CBadge :color="getBadge(item.status)"> -->
          <CBadge>
            <img v-if="item.user_sts === 'ACTIVATED'" src="@/assets/images/ico-user-active.svg" />
            <img v-if="item.user_sts === 'UNACTIVATED'" src="@/assets/images/ico-user-deactive.svg" />
          </CBadge>
        </td>
      </template>

      <template #country_name="{ item }">
        <td class="text-center">
          {{ item.country ? $t(`COUNTRY_TYPE_${item.country.name.toUpperCase()}`) : '-' }}
        </td>
      </template>

      <template #action="{ item }">
        <td class="text-center users-action">
          <img src="@/assets/images/ico-edit-user.svg" alt @click="edit(item.id)" class="hand edit-icon" />
          <img
            :class="{ disabled: item.id === userId }"
            class="ml-3 hand remove-icon"
            @click="remove(item.id, item.name)"
            src="@/assets/images/ico-del-user.svg"
          />
        </td>
      </template>
    </CDataTable>
  </CCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      fields: [
        {
          key: '#',
          label: '#',
          _style: 'width: 40px',
        },

        {
          key: 'name',
          label: this.$t('LIST_USER_COMPONENT_TABEL_COL_NAME'),
        },
        {
          key: 'email',
          label: this.$t('LIST_USER_COMPONENT_TABEL_COL_EMAIL'),
        },
        {
          key: 'role',
          label: this.$t('LIST_USER_COMPONENT_TABEL_COL_ROLE'),
        },
        {
          key: 'country_name',
          label: this.$t('LIST_USER_COMPONENT_TABLE_COL_COUNTRY_NAME'),
          _style: 'width: 100px; text-align: center',
        },
        {
          key: 'status',
          label: this.$t('LIST_USER_COMPONENT_TABEL_COL_STATUS'),
          _style: 'width: 80px; text-align: center',
        },
        {
          key: 'action',
          label: '',
          _style: 'width: 100px; text-align: center',
        },
      ],
    };
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.userId,
    }),
  },
  methods: {
    ...mapActions(['getSelectedUser']),
    getBadge(status) {
      return status === 'Active' ? 'success' : status === 'Locked' ? 'danger' : 'primary';
    },
    edit(id) {
      const userId = id;
      const user = this.users.find((user) => user.id === userId);

      this.getSelectedUser(user);
      this.$router.push(`user-management/edit-user/${userId}`);
    },
    remove(id, name) {
      if (this.userId === id) {
        return;
      }
      const userId = id;
      const userName = name;

      this.$emit('delete-user', userId, userName);
    },
  },
  name: 'ListUserTable',
};
</script>

<style lang="scss">
#lstUserTable {
  .table {
    margin-bottom: 0;
    .hand {
      cursor: pointer;
    }
    .edit-icon {
      color: #ffae00;
    }
    .remove-icon {
      color: rgba(236, 42, 42, 0.91);
      &.disabled {
        user-select: none;
        opacity: 0.5;
        cursor: auto;
      }
    }
  }
}
</style>
